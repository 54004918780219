td,
.form-label {
  white-space: nowrap;
}
.call-button {
  color: limegreen;
  text-decoration: none;
  &:hover {
    color: limegreen;
  }
}
html,
body {
  margin: 0;
  padding: 0;
}
.home-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 20px);
  overflow: hidden;
  // @media screen and (max-width: 1024px) {
    // margin-top: -70px;
    & h3 {
      font-size: calc(1rem + 1.3vw);
      margin-top: 10px;
      padding-top: 5px;
      white-space: nowrap;
      // position: absolute;
      text-align: center;
      font-weight: 700;
      //box-shadow: 0 -12px 8px -12px #545454;
    }
  // }

  header {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    position: fixed;
    z-index: 99;
    background: #fff;
    left: 10px;
    right: 10px;
    top: 0;
    box-shadow: 0px 15px 10px -15px #d7d7d7;
  }
  .home-body {
    flex: 1;
    margin-top: 210px;
    overflow-x: auto;
    // @media screen and (max-width: 1024px) {
      padding-bottom: 320px;
      margin-top: 196px;
    // }
    // @media screen and (max-width: 767px) {
    //   overflow-x: auto;
    // }

    & > ul,
    & > ul > li {
      display: flex;
      flex: 1;
      margin: 0;
      padding: 0;
      list-style-type: none;
      flex-direction: column;
    }
    & > ul > li + li {
      margin-top: 20px;
    }
    .location-header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      flex: 1;
      flex-wrap: wrap;
      // background-color: #0d6efd;
      background-color: #000;
      color: #fff;

      .header-buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-left: -0.5rem;
      }
    }
    .description {
      display: flex;
      flex: 1 0 100%;
      padding: 10px 0;
      display: none;

      .location-image {
        float: left;
        margin: 0 15px 15px 0;
        max-height: 100px;
        width: auto;
      }
    }
  }
  .header-buttons-wrapper {
    //  margin-left: -0.5rem;
    button {
      font-size: 1.2rem;
      min-width: 3rem;
    }
  }
  .inner-body {
    margin-top: 90px;
    // padding-bottom: 80px;
    // @media screen and (min-width: 1025px) {
      margin-top: 138px;
      // padding-bottom: 0px;
    // }
  }
  .home-button {
    border: none;
    width: 68px;
    cursor: pointer;
  }
  .group-dropdown {
    margin-right: 12px;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: none;
    z-index: 1;
    box-shadow: 0 -12px 8px -12px #d7d7d7;
    @media screen and (max-width: 1024px) {
      height: 60px;
      display: block;
    }
  }
}
.toggle-button {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-left: 0 !important;
  }
  label {
    color: #000;
    cursor: pointer;
  }
}
.header-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .header-nav {
  }
}

.nav-custom {
  margin-bottom: auto !important;
  margin-left: auto !important;
  // @media screen and (max-width: 1024px) {
    position: relative;
    top: 14px;
  // }
}
// @media screen and (max-width: 1024px) {
  .header-inner .nav-custom {
    position: absolute;
    right: 0;
    top: 24px;
  }
  .header-inner .name-location {
    top: 4px;
  }
  .home-wrapper header.header-inner {
    padding-bottom: 14px;
  }
// }
.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .popup {
    width: 80%;
    max-width: 300px;
    background: #fff;
    padding: 1.5rem;
    border: 1px solid #6c757d;

    button {
      margin-right: 1rem;
    }
  }
}

// @media screen and (max-width: 1024px) {
  .nav {
    position: static;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    //flex-wrap: nowrap;
    //background: red;
  }
  @media screen and (max-width: 1024px) {
    .footer-buttons-wrapper .nav-item .btn {
      margin-right: 0 !important;
      padding: 0.135rem 0.75rem !important;
      font-size: 0.75rem !important;
    }
    .footer-buttons-wrapper .nav-item .btn.me2 {
      margin-right: 0 !important;
    }
    .hide-from-mobile {
      display: none;
    }
  }
  .header-buttons-wrapper {
    margin-top: 6px;
    width: calc(100vw - 18px);
    //width: 100vw;
    padding-top: 11px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 -12px 8px -12px #d7d7d7;
  }
  .group-dropdown {
    position: absolute;
    right: 80px;
    width: calc(100vw - 176px);
    top: 22px;
    margin-right: 0px !important;
  }
  .name-location {
    position: absolute;
    right: 0;
    top: -4;
  }
  .name-location span {
    text-align: right;
    display: block;
  }
  .name-location span + span {
    margin-top: -7px;
  }
// }
.name-location span + span {
  margin-left: 5px;
}
.btn-green {
  background-color: #339933 !important;
  border-color: #268b26 !important;
}
// .name-location {
//   white-space: nowrap;
//   position: absolute;
// }

.location-details-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  max-height: 150px;
}
.location-details-wrapper > label {
  border: 1px solid #ced4da;
  padding: 0.5rem 1rem;
  flex: 1 1 auto;
  overflow: auto;
}
.location-details-wrapper > img {
  display: block;
  border: 1px solid #ced4da;
  max-width: 150px;
  object-fit: cover;
  flex: 1 0 auto;
  margin-left: 2rem;
}
.slots-button-wrapper {
  display: flex;
  button + button {
    margin-left: 1rem;
  }
}
.color-picker-wrapper {
  position: fixed;
  left: 20px;
  right: 20px;
  top: 30px;
  bottom: 30px;
  max-width: 300px;
  max-height: 400px;
  margin: auto;
  z-index: 999;
  background-color: #ffffff;
  input.ReactColorPicker-module_hex_value__iYcr2 {
    width: 100px !important;
  }
  .closebutton {
    top: -28px;
    right: -28px;
    .line1,
    .line3 {
      background: #000;
    }
  }
}
.image-popup-wrapper {
  position: fixed;
  background: gray;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  padding: 2rem 1rem 1rem;
}

//close button
.closebutton {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.closebutton .line1 {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  transform-origin: 0% 0%;
  transform: rotate(45deg);
}

.closebutton .line3 {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  transform-origin: 0% 100%;
  transform: rotate(-45deg);
}
.gallery-wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 90vh;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  margin-top: 20px;
}
.gallery-wrapper > img {
  margin-top: auto;
  width: 100%;
  max-width: 300px;
  height: 500px;
  object-fit: cover;
  flex: 1 0 auto;
}
.gallery-wrapper > img + img {
  margin-left: 20px;
}

.edit-location-images-wrapper {
  display: flex;
  border: 1px solid #ced4da;
  flex-wrap: wrap;
  picture {
    position: relative;
    margin: 1rem;
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
  }
  .closebutton {
    position: absolute;
    top: 6px;
    right: 0px;
    width: 26px;
    height: 21px;
    z-index: 0;
    .line1,
    .line3 {
      background: #000;
    }
  }
}
.heading-button-wrapper {
  display: flex;
  justify-content: space-between;
}
.react-datepicker-ignore-onclickoutside
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background: #fff;
  overflow: hidden;
}
.react-datepicker-ignore-onclickoutside input {
  background: white;
  padding: 4px;
}
.react-datepicker-ignore-onclickoutside
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}
.react-datepicker-ignore-onclickoutside legend {
  display: none;
}
.react-datepicker-ignore-onclickoutside
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0;
}
.react-datepicker-ignore-onclickoutside .MuiInputAdornment-root .MuiButtonBase-root {
  right: 10px;
  top: 0;
  bottom: 0;
  position: absolute;
  border-radius: 0;
  display: flex;
  justify-content: flex-end;
}
.no-data {
  display: flex;
  justify-content: center;
}

.carousel-root .thumbs-wrapper,
.btn-register-wrapper ~ .btn-register-wrapper,
/*.home-text-icon-wrapper ~ .btn-register-wrapper .slot-status-text*/ {
  display: none;
}
.assignment-header-wrapper {
  border-width: 0 !important;
  padding: 0 !important;
}
.user-alert {
  position: absolute;
  width: 100%;
  top: 12px;
  z-index: 111;
}
.slot-status-text {
  font-style: italic;
}
.home-fixed-table {
  table-layout: fixed;
}
.home-col-1 {
  width: 30%;
  border-width: 0 !important;
}
.home-col-2 {
  width: 70%;
  border-width: 0 !important;
}
.home-col-2-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.home-text-icon-wrapper,
.btn-register-wrapper {
  display: flex;
  width: 90%;
  max-width: 316px;
}
.home-text-icon-wrapper > label,
.btn-register-wrapper > label {
  white-space: pre-wrap;
  width: 60%;
  text-align: left;
  margin-left: 0 !important;
}
.home-slot-time {
  //white-space: pre-wrap;
  white-space: nowrap;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-bigger {
  font-size: 1.1rem;
}
.home-expired-remove {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.no-border-left {
  border-left-width: 0 !important;
}
.no-border-right {
  border-right-width: 0 !important;
}
.header-dropdown-wrapper {
  display: flex;
  flex-direction: row;
}
.usergroup-dropdown,
.search-textbox {
  max-width: 200px;
  margin-bottom: 15px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 200px;
}
.carousel .slide img {
  object-fit: contain;
  max-height: 100%;
}
