body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}

#root {
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//hamburger nav
.nav-container {
  /*display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  align-items: flex-start;*/
}

.navbar {
  /*width: 60px;
  z-index: 11;
  height: 100%;
  top: -11px;*/
}
.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
}

.navbar .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 1.2rem;
  display: block;
}

.navbar .nav-container a:hover {
  font-weight: bolder;
}

.nav-container > label {
  display: none;
  //background: red;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
.nav-container input[type='checkbox']:checked + label {
  display: block;
}
.nav-container .hamburger-lines {
  height: 26px;
  width: 32px;
  //position: absolute;
  //top: 17px;
  //left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.3s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
}

.navbar .menu-items {
  //top: 58px;
  top: 72px;
  z-index: 99;
  background: #fff;
  height: calc(100% - 72px);
  width: 320px;
  transform: translate(calc(100% + 8px));
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  position: fixed;
  right: 0;
  overflow: auto;
  box-shadow: 0px 0px 8px 0px #d7d7d7;
  @media screen and (max-width: 1024px) {
    height: calc(100% - 60px);
    width: 100%;
    top: 0px;
  }
}

.navbar .menu-items li {
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #ced4da;
  margin: 0 1.5rem;
}

.logo {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 1.2rem;
  color: #0e2431;
}

.nav-container input[type='checkbox']:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type='checkbox']:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type='checkbox']:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type='checkbox']:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type='checkbox']:checked ~ .logo {
  display: none;
}
div.alert {
  z-index: 99999;
  left: 10px;
  right: 10px;
  top: 10px;
  position: fixed;
}
.form-select.language-dropdown-login {
  display: flex !important;
  margin-left: auto;
  margin-right: 0 !important;
}
.show-only-in-mobile {
  display: none;
}
//loader
.loader {
  position: fixed;
  margin: auto;
  z-index: 99999;
  border: 8px solid transparent;
  border-radius: 50%;
  border-top: 8px solid blue;
  border-bottom: 8px solid blue;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
//end loader

//footer nav
.footer-menu-items {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  height: 100%;
  > li {
    flex: 1 0 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000000;
    span {
      font-size: 0.8rem;
      text-align: center;
    }
    svg {
      font-size: 1.5rem;
    }
    &.active svg {
      fill: #0d6efd;
    }
    &.active span {
      color: #0d6efd;
    }
  }
}
.footer-menu-items a.ham svg {
  font-size: 2.5rem;
}
//hide in mobile & language selector
@media screen and (max-width: 1024px) {
  .show-only-in-mobile {
    display: block;
  }
  .nav-container .hamburger-lines,
  .nav-container .checkbox {
    display: none;
  }
  .language-dropdown {
    position: absolute;
    right: 0;
    top: 22px;
    z-index: 10;
  }
  .donation-button {
    position: absolute;
    right: 72px;
    top: 22px;
  }
  .language-dropdown.me-2 {
    margin-right: 0 !important;
  }
}

.table {
  .form-select {
    width: auto !important;
    min-width: 100%;
  }
}

.cursor-poiner {
  cursor: pointer;
}