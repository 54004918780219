@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.App {
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}

.nav-custom {
  // border-bottom: 1px solid #939393;
  // box-shadow: 0px 0px 2px 2px #d1d1d1;
  @media screen and (max-width: 1024px) {
    border: none;
    box-shadow: none;
  }
}

.table-responsive-sm {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.actual-home-wrapper {
  .table-responsive-sm {
    overflow-x: hidden;
  }
}
.male-color {
  color: blue;
}
.female-color {
  color: red;
}

//notifications bar
.notifications-bar {
  position: fixed;
  padding: 0 2rem 0 0.6rem;
  z-index: 999;
  width: 100%;
  left: 0;
  top: 0;
  background: #ced4da;
  border-bottom: 1px solid #ced4da;
  opacity: 0.9;

  .closebutton {
    top: 6px;
    right: 0;
    span {
      background-color: #000;
    }
  }
  .notifications-data {
    padding: 0.4rem 0;
  }
}
.wrap-long-data {
  min-width: 100px;
  max-width: 200px;
  word-break: break-word;
  max-width: 100px;
  display: block;
  white-space: break-spaces;
}